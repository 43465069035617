body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	color: #000;
	overflow-x: hidden;
	font-family: 'Source Sans Pro', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
}

p {
	    font-size: 17px;
    line-height: 29px;
    font-weight: 600;
    color: #707084;
}

a {
	transition: .3s all ease-in-out;
	-webkit-transition: .3s all ease-in-out;
	-ms-transition: .3s all ease-in-out;
	-moz-transition: .3s all ease-in-out;
	-o-transition: .3s all ease-in-out;
	color: inherit;
}

a:hover {
	text-decoration: none !important;
	color: inherit;
}

ol,
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

*:focus {
	outline: none !important;
	box-shadow: inherit !important;
}

img {
	max-width: 100%;
}
.btn_submit {
    border: 0;
    background-color: #4FD7B7;
    color: #fff;
    padding: 5px 35px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 67px;
}
.btn_submit:hover {
    background-color: #000 !important;
    color: #fff !important;
}

/***************************************/

.sharify_user {
    background-image: url(../images/Clip-bg.png);
    padding: 30px 0px 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #F9FAFC;
    background-attachment: fixed;
}

.sharify_center_user {
    background-color: rgb(255 255 255 / 65%);
    padding: 100px 140px;
    box-shadow: 0px 30px 34px 0px rgb(0 0 0 / 2%);
    border-radius: 6px;
    min-height: 810px;
    height: 100%;
}

.sharify_center_user .form-control {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #e0e7ed;
    border-radius: 0px;
    padding: 0px 0px 0px 4px;
    margin-bottom: 25px;
    color: #707084;
}
.sharify_center_user .form-control:focus {
    background-color: transparent !important;
    border-color: #80bdff;
}
.text_user p {
    font-size: 18px;
    margin-bottom: 25px;
}
.logo {
    margin-bottom: 50px;
}
.sharify_center_user .form-group.checkbox {
    padding-left: 20px;
    color: #707084;
}
/************login************/
.forget_password {
    text-align: right;
    font-size: 12px;
    width: 100%;
}
.login-page .btn_submit {
    border: 0;
    background-color: #4FD7B7;
    color: #fff;
    padding: 5px 35px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 137px;
}
form.login {
    margin-top: 55px;
}
/**************Account not yet activated*****************/

.account-not-yet-activated p {
    font-size: 18px;
}
.account-not-yet-activated {
    margin-top: 56px;
}
/***********reset password***********/

p.text_password_top {
    margin-top: 150px;
    margin-bottom: 64px;
}
/*************restart passward**********************************/
.reset-password {
    margin-top: 95px;
}
/*************Forgot passward**********************************/
.Forgot-Password {
    margin-top: 125px;
}
/*************wait for active*************/
.Waiting-for-activation .waiting-activation {
    margin-top: 55px;
}
/****************account deleted*******************/

.Account-deleted_section {
    margin: 140px 0px;
}
.back_to_home a {
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #707084;
}
/*************************dashboard*****************************/
.dashboard{
    background-image: url(../images/Clip-bg.png);
    
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #F9FAFC;
}
.dashboard ul#accordionSidebar {
    padding-top: 65px;
}


.error {
    color:#db2269 !important;
    font-size: 0.625em !important;
    display: relative;
  }

  

@media(max-width:767px) {
.sharify_center_user {
    padding: 40px 40px;
    
}
    
}